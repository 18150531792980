import {Injectable} from '@angular/core';
import {User} from "../models/users/user";
import {Params, Router} from '@angular/router';
import {ExpertOnboardingSteps} from "../enums/expert-onboarding-steps";
import {SubProjectProductType} from '../types/sub-project-product-type';
import {SegmentTemplate} from "../models/segment-templates/segment-template";

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(private router: Router) {
  }

  forceRefresh(): void {
    location.reload();
  }

  // Login

  getExpertLoginUrl(): any[] {
    return ['/login', 'expert-login'];
  }

  navigateToExpertLogin(redirectUrl: any = ''): void {
    this.router.navigate(this.getExpertLoginUrl());
  }

  getClientLoginUrl(): any[] {
    return ['/login', 'client-login'];
  }

  navigateToClientLogin(): void {
    this.router.navigate(this.getClientLoginUrl());
  }

  getLoginUrl(): any[] {
    return ['/login'];
  }

  navigateToLogin(): void {
    this.router.navigate(this.getLoginUrl());
  }

  getClientRegistrationUrl(): any[] {
    return ['/login', 'client-registration'];
  }

  navigateToClientRegistration(): void {
    this.router.navigate(this.getClientRegistrationUrl());
  }

  getForgotMyPasswordUrl(): any[] {
    return ['/login', 'forgot-my-password'];
  }

  navigateToForgotMyPassword(): void {
    this.router.navigate(this.getForgotMyPasswordUrl());
  }
  getAdminSearcherPageUrl(searcherId: number): any[] {
    return ['/dashboard','admin', 'sub-projects', 'searcher', searcherId];
  }
  navigateToAdminSearcherPage(id: number) {
    this.router.navigate(this.getAdminSearcherPageUrl(id));
  }

  getIquoteOwnerSegmentsViewPageUrlForClient(subProjectId: number): any[] {
    return ['/dashboard', 'client','iquote', 'segments-view', subProjectId];
  }

  getIquoteOwnerSegmentsViewPageUrlForAdmin(subProjectId: number): any[] {
    return ['/dashboard', 'admin','iquote', 'segments-view', subProjectId];
  }

  getIquoteOwnerDashboardPageUrlForClient(): any[] {
    return ['/dashboard', 'client','iquote'];
  }

  getIquoteOwnerDashboardPageUrlForAdmin(): any[] {
    return ['/dashboard', 'admin','iquote'];
  }

  navigateIquoteOwnerDashboardPageUrl(user: User){
    this.router.navigate(user.isAdmin? this.getIquoteOwnerDashboardPageUrlForAdmin(): this.getIquoteOwnerDashboardPageUrlForClient());
  }

  getAdminSegmentPageUrl(segmentId: number): any[] {
    return ['/dashboard','admin','sub-projects', 'segment', segmentId];
  }
  navigateToAdminSegmentPage(segmentId: number) {
    this.router.navigate(this.getAdminSegmentPageUrl(segmentId));
  }
  // Expert registration

  getExpertRegistrationUrl(expertId: number, secretId: string): any[] {
    return ['/expert-registration', expertId, secretId];
  }

  navigateToExpertRegistration(expertId: number, secretId: string): void {
    this.router.navigate(this.getExpertRegistrationUrl(expertId, secretId));
  }



  getExpertUnsubscribeUrl(expertId: number, secretId: string): any[] {
    return ['/expert-registration', 'expert-unsubscribe', expertId, secretId];
  }

  navigateToExpertUnsubscribe(expertId: number, secretId: string): void {
    this.router.navigate(this.getExpertUnsubscribeUrl(expertId, secretId));
  }

  // Home

  navigateToRoot(currentQueryParams?:Params): void {
    this.router.navigate(['/'],{queryParams:currentQueryParams});
  }

  async navigateToRootAndRefresh(): Promise<void> {
    await this.router.navigate(['/']);
    this.forceRefresh();
  }

  getDashboardUrl(type?: string): string[] {
    if (type) {
      return ['/dashboard', type];
    } else {
      return ['/dashboard'];
    }
  }

  navigateToDashboard(currentParameters?: Params, type?: string): void {
    this.router.navigate(this.getDashboardUrl(type), {queryParams: currentParameters});
  }


  // Dashboard


  getDashboardProjectsUrl(): any[] {
    return ['/dashboard', 'projects'];
  }

  navigateToDashboardProjects(): void {
    this.router.navigate(this.getDashboardProjectsUrl());
  }

  getDashboardClientProjectUrl(projectId: number): any[] {
    return ['/dashboard', 'client' ,'study' , 'project', projectId];
  }

  navigateToDashboardClientProject(projectId: number): void {
    this.router.navigate(this.getDashboardClientProjectUrl(projectId));
  }

  getDashboardSubProjectUrlByClient(subprojectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected' , subprojectId, 'overview'];
  }

  getDashboardSubProjectFilesUrl(subprojectId: number): any[] {
    return ['/dashboard', 'sub-project', subprojectId, 'view-files'];
  }

  navigateToDashboardSubProjectByClient(subProjectId: number, isNew?:boolean): void {
    const params = isNew ? {isNew} : {};
    this.router.navigate(this.getDashboardSubProjectUrlByClient(subProjectId), {queryParams:params});
  }

  forceNavigateToDashboardSubProject(subProjectId: number): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(this.getDashboardSubProjectUrlByClient(subProjectId));
    });
  }

  getDashboardEngagementUrl(engagementId: number): any[] {
    return ['/dashboard', 'engagement', engagementId];
  }

  navigateToDashboardEngagement(engagementId: number): void {
    this.router.navigate(this.getDashboardEngagementUrl(engagementId));
  }

  getDashboardEngagementScheduleUrl(engagementId: number): any[] {
    return ['/dashboard', 'engagement', engagementId, 'schedule'];
  }

  navigateToDashboardScheduleEngagement(engagementId: number): void {
    this.router.navigate(this.getDashboardEngagementScheduleUrl(engagementId));
  }

  getDashboardEngagementSuggestOtherTimesUrl(engagementId: number): any[] {
    return ['/dashboard', 'engagement', engagementId, 'suggest-other-times'];
  }

  navigateToDashboardEngagementSuggestOtherTimes(engagementId: number): void {
    this.router.navigate(this.getDashboardEngagementSuggestOtherTimesUrl(engagementId));
  }

  getDashboardEngagementViewSuggestOtherTimesUrl(engagementId: number): any[] {
    return ['/dashboard', 'engagement', engagementId, 'view-suggest-other-times'];
  }

  navigateToDashboardEngagementViewSuggestOtherTimes(engagementId: number): void {
    this.router.navigate(this.getDashboardEngagementViewSuggestOtherTimesUrl(engagementId));
  }

  getDashboardSubProjectViewExpertsUrl(subProjectId: number): any[] {
    return ['/dashboard', 'sub-project', subProjectId, 'view-experts'];
  }

  navigateToDashboardSubProjectViewExperts(subProjectId: number): void {
    this.router.navigate(this.getDashboardSubProjectViewExpertsUrl(subProjectId));
  }

  getDashboardCallUrl(callId: number): any[] {
    return ['/dashboard', 'call', callId];
  }

  navigateToDashboardCall(callId: number, tab?: string): void {
    if (tab) {
      this.router.navigate(this.getDashboardCallUrl(callId), {queryParams: {tab}});
    } else {
      this.router.navigate(this.getDashboardCallUrl(callId));
    }
  }

  getClientProfileUrl() {
    return ['/dashboard', 'client', 'settings', 'profile'];
  }

  getExpertProfileUrl() {
    return ['/dashboard', 'expert', 'settings', 'profile'];
  }

  getExpertDashboardUrl() {
    return ['/dashboard', 'expert'];
  }

  getUserAccountUrl() {
    return ['/dashboard', 'user', 'account'];
  }


  getDashboardClientTeamsUrl() {
    return ['/dashboard', 'client', 'settings', 'teams'];
  }

  navigateToDashboardClientTeams(): void {
    this.router.navigate(this.getDashboardClientTeamsUrl());
  }


  getDashboardClientUserRolesUrl() {
    return ['/dashboard', 'client', 'settings', 'user-roles'];
  }

  getDashboardClientOrganizationUrl() {
    return ['/dashboard', 'client', 'settings', 'organization'];
  }

  navigateToDashboardClientOrganization(): void {
    this.router.navigate(this.getDashboardClientOrganizationUrl());
  }

  navigateToDashboardClientUserRoles(): void {
    this.router.navigate(this.getDashboardClientUserRolesUrl());
  }

  getDashboardUserNotificationsUrl() {
    return ['/dashboard', 'user', 'notifications'];
  }

  navigateToDashboardUserNotifications(): void {
    this.router.navigate(this.getDashboardUserNotificationsUrl());
  }

  getClientDocumentsUrl() {
    return ['/dashboard', 'client', 'settings', 'documents'];
  }

  navigateToClientDocuments(): void {
    this.router.navigate(this.getClientDocumentsUrl());
  }

  navigateToUserAccount(): void {
    this.router.navigate(this.getUserAccountUrl());
  }

  navigateToUsersProfile(): void {
    this.router.navigate(this.getClientProfileUrl());
  }

  navigateToUnsubscribeUser() {
    this.router.navigate(this.getClientProfileUrl(), {queryParams: {unsubscribe: true}});
  }


  getDashboardLibraryUrl(): any[] {
    return ['/dashboard', 'library'];
  }

  navigateToDashboardLibrary(): void {
    this.router.navigate(this.getDashboardLibraryUrl());
  }


  getDashboardExpertAvailabilityUrl(): any[] {
    return ['/dashboard', 'expert', 'settings', 'availability'];
  }

  getDashboardClientConsentDocumentsUrl(): any[] {
    return ['/dashboard', 'client', 'consent-documents'];
  }

  getDashboardClientGovernanceUrl(): any[] {
    return ['/dashboard', 'client', 'settings', 'governance'];
  }

  navigateToDashboardClientGovernance(): void {
    this.router.navigate(this.getDashboardClientGovernanceUrl());
  }


  getDashboardClientSubProjectUrl(): any[] {
    return ['/dashboard', 'client', 'study'];
  }

  getDashboardExpertSubProjectUrl(): any[] {
    return ['/dashboard', 'expert', 'my-studies'];
  }
  navigateToDashboardExpertSubProject(): void {
    this.router.navigate(this.getDashboardExpertSubProjectUrl());
  }

  getDashboardExpertMySubProjectUrl(): any[] {
    return ['/dashboard', 'expert', 'my-studies'];
  }
  navigateToDashboardExpertMySubProject(): void {
    this.router.navigate(this.getDashboardExpertMySubProjectUrl());
  }
  getDashboardExpertEngagementScreenerScrollableScreeningQuestionsUrl(engagementId: number): any[] {
    return ['/dashboard', 'expert', 'engagement', 'screener','screener-page',engagementId, 'scrollable-screening-questions'];
  }

  getExternalExpertEngagementScreenerScrollableScreeningQuestionsUrl(engagementId: number, externalProviderId:number, externalProviderSecretId:string): any[] {
    return ['/external-provider-zone', engagementId, 'scrollable-screening-questions', externalProviderId, externalProviderSecretId];
  }

  navigateToExternalExpertEngagementScreenerScrollableScreeningQuestions(engagementId: number, externalProviderId:number, externalProviderSecretId:string): void {
    this.router.navigate(this.getExternalExpertEngagementScreenerScrollableScreeningQuestionsUrl(engagementId, externalProviderId, externalProviderSecretId));
  }

  getExternalExpertEngagementConsultationCallScreenerCompletionSurveyUrl(engagementId: number, externalProviderId:number, externalProviderSecretId:string): any[] {
    return ['/external-provider-zone', engagementId, 'completion-consultation-call' , externalProviderId, externalProviderSecretId];
  }

  navigateExternalExpertEngagementConsultationCallScreenerCompletionSurvey(engagementId: number, externalProviderId:number, externalProviderSecretId:string): void {
    this.router.navigate(this.getExternalExpertEngagementConsultationCallScreenerCompletionSurveyUrl(engagementId, externalProviderId, externalProviderSecretId));
  }



  getExternalExpertEngagementSurveyScreenerCompletionSurveyUrl(engagementId: number, externalProviderId:number, externalProviderSecretId:string): any[] {
    return ['/external-provider-zone', engagementId, 'completion-survey' , externalProviderId, externalProviderSecretId];
  }

  navigateExternalExpertEngagementSurveyScreenerCompletionSurvey(engagementId: number, externalProviderId:number, externalProviderSecretId:string): void {
    this.router.navigate(this.getExternalExpertEngagementSurveyScreenerCompletionSurveyUrl(engagementId, externalProviderId, externalProviderSecretId));
  }


  getExternalExpertEngagementConsultationCallCompletionAvailabilitySurveyUrl(engagementId: number, externalProviderId:number, externalProviderSecretId:string): any[] {
    return ['/external-provider-zone', engagementId, 'consultation-call-availability' , externalProviderId, externalProviderSecretId];
  }

  navigateExternalExpertEngagementConsultationCallCompletionAvailabilitySurveyUrl(engagementId: number, externalProviderId:number, externalProviderSecretId:string): void {
    this.router.navigate(this.getExternalExpertEngagementConsultationCallCompletionAvailabilitySurveyUrl(engagementId, externalProviderId, externalProviderSecretId));
  }


  getDashboardExpertEngagementScreenerCompletionSurveyUrl(engagementId: number): any[] {
    return ['/dashboard', 'expert', 'engagement', 'screener','screener-page',engagementId, 'completion-survey'];
  }
  navigateToDashboardExpertEngagementScreenerCompletionSurvey(engagementId: number): void {
    this.router.navigate(this.getDashboardExpertEngagementScreenerCompletionSurveyUrl(engagementId));
  }
  getDashboardExpertEngagementScreenerCompletionConsultationCallUrl(engagementId: number): any[] {
    return ['/dashboard', 'expert', 'engagement', 'screener','screener-page',engagementId, 'completion-consultation-call'];
  }
  navigateToDashboardExpertEngagementScreenerCompletionConsultationCall(engagementId: number): void {
    this.router.navigate(this.getDashboardExpertEngagementScreenerCompletionConsultationCallUrl(engagementId));
  }

  navigateToExternalExpertEngagementScreenerOnePerPageScreeningQuestions(engagementId: number, id: number, externalProviderId:number, externalProviderSecretId:string){
    this.router.navigate(this.getExternalExpertEngagementScreenerOnePerPageScreeningQuestionsUrl(engagementId, id, externalProviderId, externalProviderSecretId));
  }
  getExternalExpertEngagementScreenerOnePerPageScreeningQuestionsUrl(engagementId: number, id: number, externalProviderId:number, externalProviderSecretId:string){
    return ['/external-provider-zone',engagementId, 'one-per-page-screening-questions' , id, externalProviderId, externalProviderSecretId];
  }



  navigateToDashboardExpertEngagementScreenerScrollableScreeningQuestions(engagementId: number): void {
    this.router.navigate(this.getDashboardExpertEngagementScreenerScrollableScreeningQuestionsUrl(engagementId));
  }
  getDashboardExpertEngagementScreenerOnePerPageScreeningQuestionsUrl(engagementId: number, id: number){
    return ['/dashboard', 'expert', 'engagement', 'screener','screener-page',engagementId, 'one-per-page-screening-questions',id];
  }

  navigateToDashboardExpertEngagementScreenerOnePerPageScreeningQuestions(engagementId: number, id: number){
    this.router.navigate(this.getDashboardExpertEngagementScreenerOnePerPageScreeningQuestionsUrl(engagementId, id));
  }

  getDashboardExpertEngagementScreenerAvailabilityUrl(engagementId: number){
    return ['/dashboard', 'expert', 'engagement', 'screener','screener-page',engagementId, 'availability'];
  }
  navigateToDashboardExpertEngagementScreenerAvailability(engagementId: number){
    this.router.navigate(this.getDashboardExpertEngagementScreenerAvailabilityUrl(engagementId));
  }



  getExternalExpertScreenerWelcomePage(engagementId:number, externalProviderId:number, externalProviderSecret:string): any[] {
    return ['/external-provider-zone', engagementId, 'welcome-page', externalProviderId , externalProviderSecret];
  }

  navigateToExternalExpertScreenerWelcomePage(engagementId:number, externalProviderId:number, externalProviderSecret:string): void {
    this.router.navigate(this.getExternalExpertScreenerWelcomePage(engagementId, externalProviderId, externalProviderSecret));
  }

  getDashboardExpertEngagementScreenerPageUrl(engagementId: number): any[] {
    return ['/dashboard', 'expert', 'engagement', 'screener', 'screener-page',engagementId];
  }
  getDashboardExpertEngagementScreenerWelcomePageUrl(engagementId: number): any[] {
    return ['/dashboard', 'expert', 'engagement', 'screener','screener-page',engagementId, 'welcome-page'];
  }
  navigateToDashboardExpertEngagementScreenerWelcomePage(engagementId: number): void {
    this.router.navigate(this.getDashboardExpertEngagementScreenerWelcomePageUrl(engagementId));
  }

  getDashboardExpertRecommendedSubProjectUrl(): any[] {
    return ['/dashboard', 'expert', 'my-studies', 'recommended-studies'];
  }

  getDashboardExpertInvitedSubProjectUrl(): any[] {
    return ['/dashboard', 'expert', 'my-studies', 'invited'];
  }

  getDashboardExpertActiveSubProjectUrl(): any[] {
    return ['/dashboard', 'expert', 'my-studies', 'active'];
  }

  getDashboardExpertParticipatedSubProjectUrl(): any[] {
    return ['/dashboard', 'expert', 'my-studies', 'participated'];
  }

  getDashboardExpertInterviewsUrl(filterName?: string): any[] {
    return ['/dashboard', 'expert', 'interviews', ...(filterName ? [filterName] : [])];
  }

  navigateToDashboardExpertInterviewsUrl(filterName?: string): void {
    this.router.navigate(this.getDashboardExpertInterviewsUrl(filterName));
  }

  getDashboardClientCreateSegmentUrl(): any[] {
    return ['/dashboard', 'client', 'segment', 'create'];
  }

  navigateToDashboardClientCreateSegment(subProjectId?:number): void {
    this.router.navigate(this.getDashboardClientCreateSegmentUrl(),{queryParams: {subProjectId}});
  }

  getDashboardClientModifySegmentUrl(): any[] {
    return ['/dashboard', 'client', 'segment', 'modify'];
  }

  navigateToDashboardClientModifySegment(existingSegmentTemplate:SegmentTemplate): void {
    this.router.navigate(this.getDashboardClientModifySegmentUrl(), {state: {segmentTemplate:existingSegmentTemplate}});
  }


  getDashboardClientSegmentUrl(): any[] {
    return ['/dashboard', 'client', 'segment'];
  }

  navigateToDashboardClientSegment(): void {
    this.router.navigate(this.getDashboardClientSegmentUrl());
  }

  getDashboardClientPeopleUrl(): any[] {
    return ['/dashboard', 'client', 'people'];
  }

  getDashboardClientPanelUrl(): any[] {
    return ['/dashboard', 'client', 'people', 'panel'];
  }

  getDashboardClientAllPeopleUrl(): any[] {
    return ['/dashboard', 'client', 'people'];
  }

  navigateToDashboardClientPanel(): void {
    this.router.navigate(this.getDashboardClientPanelUrl());
  }

  getDashboardExpertPanelUrl(): any[] {
    return ['/dashboard', 'expert', 'panel'];
  }

  navigateToDashboardExpertPanel(): void {
    this.router.navigate(this.getDashboardExpertPanelUrl());
  }

  getDashboardExpertJoinedPanelUrl(): any[] {
    return ['/dashboard', 'expert', 'panel', 'joined'];
  }

  navigateToDashboardExpertJoinedPanel(): void {
    this.router.navigate(this.getDashboardExpertJoinedPanelUrl());
  }

  getDashboardExpertRecommendedPanelUrl(): any[] {
    return ['/dashboard', 'expert', 'panel', 'recommended'];
  }

  navigateToDashboardExpertRecommendedPanel(): void {
    this.router.navigate(this.getDashboardExpertRecommendedPanelUrl());
  }

  getDashboardExpertInvitedPanelUrl(): any[] {
    return ['/dashboard', 'expert', 'panel', 'invited'];
  }

  navigateToDashboardExpertInvitedPanel(): void {
    this.router.navigate(this.getDashboardExpertInvitedPanelUrl());
  }

  getDashboardExpertRequestedPanelUrl(): any[] {
    return ['/dashboard', 'expert', 'panel', 'requested'];
  }

  navigateToDashboardExpertRequestedPanel(): void {
    this.router.navigate(this.getDashboardExpertRequestedPanelUrl());
  }

  getDashboardExpertSelectedPanelUrl(panelId: number): any[] {
    return ['/dashboard', 'expert', 'panel', 'selected-panel', panelId];
  }

  navigateToDashboardExpertSelectedPanel(panelId: number): void {
    this.router.navigate(this.getDashboardExpertSelectedPanelUrl(panelId));
  }

  getDashboardClientSelectedPanelUrl(panelId: number): any[] {
    return ['/dashboard', 'client', 'people', 'panel', 'selected-panel', panelId];
  }

  navigateToDashboardClientSelectedPanel(panelId: number): void {
    this.router.navigate(this.getDashboardClientSelectedPanelUrl(panelId));
  }

  getDashboardClientCreateSubProjectQuantUrl(): any[] {
    return ['/dashboard', 'client', 'study', 'create-study', 'quant', 'plan', 'welcome-page'];
  }

  getDashboardClientCreateSubProjectQualUrl(projectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'create-study', projectId, 'qual', 'plan', 'welcome-page'];
  }

  getDashboardClientCreateSubProjectUrl(projectId: number, type: SubProjectProductType, segment:string, page:string): any[] {
    return ['/dashboard', 'client', 'study', 'create-study', projectId, type, segment, page];
  }

  getDashboardClientEditSubProjectUrl(subProjectId: number, segment:string, page:string, subProjectType:string): any[] {
    return ['/dashboard', 'client', 'study', 'edit-study', subProjectId, subProjectType, segment, page];
  }
  getDashboardAdminEditSubProjectUrl(subProjectId: number, segment:string, page:string, subProjectProduct:string): any[] {
    return ['/dashboard', 'admin', 'edit-study', subProjectId, subProjectProduct, segment, page];
  }

  async navigateToDashboardClientCreateSubProjectUrl(projectId: number, type: SubProjectProductType, segment:string,
                                                     page:string): Promise<void> {
    await this.router.navigate(this.getDashboardClientCreateSubProjectUrl(projectId, type, segment, page));
  }

  async navigateToDashboardClientEditSubProjectUrl(subProjectId: number, segment:string,
                                                     page:string, subProjectType:string): Promise<void> {
    await this.router.navigate(this.getDashboardClientEditSubProjectUrl(subProjectId, segment, page, subProjectType));
  }
  async navigateToDashboardAdminEditSubProjectUrl(subProjectId: number, segment:string,
                                                   page:string, subProjectProduct:string): Promise<void> {
    await this.router.navigate(this.getDashboardAdminEditSubProjectUrl(subProjectId, segment, page, subProjectProduct));
  }
  async navigateToDashboardClientStartCreateStudy(projectId: number, type: SubProjectProductType) {
    await this.router.navigate(this.getDashboardClientCreateSubProjectUrl(projectId, type, 'plan', 'welcome-page'));
  }

  async navigateToDashboardClientStartEditSubProject(subProjectId: number, subProjectType:string) {
    await this.router.navigate(this.getDashboardClientEditSubProjectUrl(subProjectId, 'plan', 'welcome-page', subProjectType));
  }

  getDashboardClientArchiveSubProjectUrl(): any[] {
    return ['/dashboard', 'client', 'study', 'archive'];
  }

  navigateToDashboardClientSubProject(isNew?:boolean): void {
    this.router.navigate(this.getDashboardClientSubProjectUrl(), {queryParams: {isNew}});
  }


  navigateToDashboardClientConsentDocuments(): void {
    this.router.navigate(this.getDashboardClientConsentDocumentsUrl());
  }

  navigateToDashboardExpertAvailability(): void {
    this.router.navigate(this.getDashboardExpertAvailabilityUrl());
  }

  getDashboardClientConnectedAppsUrl(): any[] {
    return ['/dashboard', 'client' , 'settings', 'connected-apps'];
  }

  getDashboardClientQuestionTemplateUrl(): any[] {
    return ['/dashboard', 'client' , 'settings', 'question-template'];
  }

  navigateToDashboardClientConnectedApps(): void {
    this.router.navigate(this.getDashboardClientConnectedAppsUrl());
  }

  getDashboardExpertPaymentInfoUrl(): any[] {
    return ['/dashboard','expert','settings', 'payment'];
  }

  getDashboardExpertConsentFormsUrl(): any[] {
    return ['/dashboard','expert','settings', 'consent-forms'];
  }

  navigateToDashboardExpertPaymentInfo(): void {
    this.router.navigate(this.getDashboardExpertPaymentInfoUrl());
  }

  getDashboardExpertMyInfoUrl(): any[] {
    return ['/dashboard', 'expert-my-info'];
  }

  navigateToDashboardExpertMyInfo(): void {
    this.router.navigate(this.getDashboardExpertMyInfoUrl());
  }

  getDashboardExpertEngagementsUrl(): any[] {
    return ['/dashboard', 'expert-engagements'];
  }

  navigateToDashboardExpertEngagements(): void {
    this.router.navigate(this.getDashboardExpertEngagementsUrl());
  }

  getDashboardExpertEngagementUrl(engagementId: number): any[] {
    return ['/dashboard', 'expert-engagement', engagementId];
  }

  navigateToDashboardExpertEngagement(engagementId: number): void {
    this.router.navigate(this.getDashboardExpertEngagementUrl(engagementId));
  }

  getDashboardMyCalendarUrl(isClient:boolean, isAdmin?:boolean): any[] {
    if (isAdmin) {
      return ['/dashboard', 'admin', 'my-calendar'];
    }
    if (isClient) {
      return ['/dashboard', 'client', 'my-calendar'];
    }
    return ['/dashboard', 'expert', 'my-calendar'];
  }

  navigateToDashboardMyCalendar(isClient:boolean): void {
    this.router.navigate(this.getDashboardMyCalendarUrl(isClient));
  }

  getDashboardClientAvailabilityUrl(): any[] {
    return ['/dashboard', 'client', 'settings' , 'availability'];
  }

  getDashboardClientEmailUrl(): any[] {
    return ['/dashboard', 'client', 'settings',  'email'];
  }

  navigateToDashboardClientEmail(): void {
    this.router.navigate(this.getDashboardClientEmailUrl());
  }

  navigateToDashboardClientAvailability(): void {
    this.router.navigate(this.getDashboardClientAvailabilityUrl());
  }

  getDashboardExpertUrl(expertId: number): any[] {
    return ['/dashboard', 'expert', expertId];
  }

  navigateToDashboardExpert(expertId: number): void {
    this.router.navigate(this.getDashboardExpertUrl(expertId));
  }

  getDashboardNewConsultationCallSubProjectUrl() {
    return ['/dashboard', 'new-consultation-call-sub-project'];
  }

  getDashboardNewSurveySubProjectUrl() {
    return ['/dashboard', 'new-survey-sub-project'];
  }

  navigateToDashboardNewConsultationCallSubProject(): void {
    this.router.navigate(this.getDashboardNewConsultationCallSubProjectUrl(), {queryParams: {relationshipType: 'none'}});
  }

  navigateToDashboardNewSurveySubProject(): void {
    this.router.navigate(this.getDashboardNewSurveySubProjectUrl(), {queryParams: {relationshipType: 'none'}});
  }

  navigateToDashboardNewConsultationCallSubProjectForProject(projectId: number): void {
    this.router.navigate(this.getDashboardNewConsultationCallSubProjectUrl(), {
      queryParams: {
        relationshipType: 'none',
        projectId
      }
    });
  }

  navigateToDashboardNewSurveySubProjectForProject(projectId: number): void {
    this.router.navigate(this.getDashboardNewSurveySubProjectUrl(), {
      queryParams: {
        relationshipType: 'none',
        projectId
      }
    });
  }

  navigateToDashboardDuplicateConsultationCallSubProject(subProjectId: number): void {
    this.router.navigate(this.getDashboardNewConsultationCallSubProjectUrl(), {
      queryParams: {
        relationshipType: 'duplicate',
        subProjectId
      }
    });
  }

  navigateToDashboardDuplicateSurveySubProject(subProjectId: number): void {
    this.router.navigate(this.getDashboardNewSurveySubProjectUrl(), {
      queryParams: {
        relationshipType: 'duplicate',
        subProjectId
      }
    });
  }

  navigateToDashboardCreateLinkedConsultationCallSubProject(subProjectId: number): void {
    this.router.navigate(this.getDashboardNewConsultationCallSubProjectUrl(), {
      queryParams: {
        relationshipType: 'create_linked',
        subProjectId
      }
    });
  }

  getDashboardEditSubProjectUrl(subProjectId: number) {
    return ['/dashboard', 'edit-sub-project', subProjectId];
  }

  navigateToDashboardEditSubProject(subProjectId: number): void {
    this.router.navigate(this.getDashboardEditSubProjectUrl(subProjectId));
  }

  // Management

  getDashboardManageUsersUrl() {
    return ['/dashboard', 'management', 'users'];
  }

  navigateToDashboardManageUsers(): void {
    this.router.navigate(this.getDashboardManageUsersUrl());
  }


  getDashboardManageTeamsUrl() {
    return ['/dashboard', 'management', 'teams'];
  }

  navigateToDashboardManageTeams(): void {
    this.router.navigate(this.getDashboardManageTeamsUrl());
  }


  getDashboardOrganizationSettingsUrl() {
    return ['/dashboard', 'management', 'organization-settings'];
  }

  navigateToDashboardOrganizationSettingsUrl(): void {
    this.router.navigate(this.getDashboardOrganizationSettingsUrl());
  }

  getBuyCreditsPageUrl() {
    return ['/dashboard', 'buy-credits'];
  }

  navigateToBuyCreditsPageUrl(scheduleEngagementId?: number): void {
    const queryParams = scheduleEngagementId ? {schedule_engagement_id: scheduleEngagementId} : {};
    this.router.navigate(this.getBuyCreditsPageUrl(), {queryParams});
  }

  getCreditsCheckoutSuccessPageUrl() {
    return ['/dashboard', 'credits-checkout-success'];
  }

  navigateToCreditsCheckoutSuccessPageUrl(): void {
    this.router.navigate(this.getCreditsCheckoutSuccessPageUrl());
  }

  getCreditsCheckoutCancelPageUrl() {
    return ['/dashboard', 'credits-checkout-cancel'];
  }

  navigateToCreditsCheckoutCancelPageUrl(): void {
    this.router.navigate(this.getCreditsCheckoutCancelPageUrl());
  }

  // Admin

  getAdminSubProjectExpertsUrl(subProjectId: number): any[] {
    return ['/admin-pages', 'sub-project', subProjectId, 'experts'];
  }

  navigateToAdminSubProjectExperts(subProjectId: number): void {
    this.router.navigate(this.getAdminSubProjectExpertsUrl(subProjectId));
  }

  getAdminSubProjectsUrl(): any[] {
    return ['/dashboard', 'admin', 'sub-projects'];
  }

  getAdminSubProjectUrl(subProjectId:number): any[] {
    return ['/dashboard', 'admin', 'sub-projects', subProjectId];
  }

  navigateToAdminSubProject(subProjectId:number): void {
    this.router.navigate(this.getAdminSubProjectUrl(subProjectId));
  }

  getAdminExpertReportGenerator(): any[] {
    return ['/admin-pages', 'expert-report-generator'];
  }

  navigateToAdminSubProjects(): void {
    this.router.navigate(this.getAdminSubProjectsUrl());
  }

  getAdminEmailsFlowsUrl(): any[] {
    return ['/dashboard', 'admin' , 'email-flows'];
  }

  navigateToAdminEmailsFlows(): void {
    this.router.navigate(this.getAdminEmailsFlowsUrl());
  }

  getAdminCompositeEmailsUrl(): any[] {
    return ['/dashboard', 'admin' , 'composite-emails'];
  }

  navigateToAdminCompositeEmails(): void {
    this.router.navigate(this.getAdminCompositeEmailsUrl());
  }

  getAdminSubProjectAddOrigEngagement(subProjectId: number): any[] {
    return ['/admin-pages', 'sub-project', subProjectId, 'add-orig-engagement'];
  }

  navigateToAdminSubProjectAddOrigEngagement(subProjectId: number): void {
    this.router.navigate(this.getAdminSubProjectAddOrigEngagement(subProjectId));
  }

  getAdminCalendarUrl(): any[] {
    return ['/admin-pages', 'calendar'];
  }

  navigateToAdminCalendar(): void {
    this.router.navigate(this.getAdminCalendarUrl());
  }

  getAdminLinkedInUsersUrl(): any[] {
    return ['/admin-pages', 'linkedin-users'];
  }

  navigateToAdminLinkedInUsers(): void {
    this.router.navigate(this.getAdminLinkedInUsersUrl());
  }

  getAdminStatisticsUrl(): any[] {
    return ['/admin-pages', 'statistics'];
  }

  navigateToAdminStatistics(): void {
    this.router.navigate(this.getAdminStatisticsUrl());
  }

  getAdminCallsUrl(): any[] {
    return ['/admin-pages', 'calls'];
  }

  navigateToAdminCalls(): void {
    this.router.navigate(this.getAdminCallsUrl());
  }

  getAdminCallsForPaymentUrl(): any[] {
    return ['/admin-pages', 'calls-for-payment'];
  }

  navigateToAdminCallsForPayment(): void {
    this.router.navigate(this.getAdminCallsForPaymentUrl());
  }

  getAdminCallsForPaymentByOrganizationUrl(organizationId: number): any[] {
    return ['/admin-pages', 'calls-for-payment', 'organization', organizationId];
  }

  navigateToAdminCallsForPaymentByOrganization(organizationId: number): void {
    this.router.navigate(this.getAdminCallsForPaymentByOrganizationUrl(organizationId));
  }

  getAdminChatUrl(chatId: number): any[] {
    return ['/admin-pages', 'chat', chatId];
  }

  navigateToAdminChat(chatId: number): void {
    this.router.navigate(this.getAdminChatUrl(chatId));
  }

  getExpertChatUrl(chatId: number): any[] {
    return ['/dashboard', 'chat', chatId, 'expert-chat'];
  }

  navigateToExpertChat(chatId: number): void {
    this.router.navigate(this.getExpertChatUrl(chatId));
  }

  getClientChatUrl(chatId: number): any[] {
    return ['/dashboard', 'chat', chatId, 'client-chat'];
  }

  navigateToClientChat(chatId: number): void {
    this.router.navigate(this.getClientChatUrl(chatId));
  }

  getAdminAllChatsUrl(): any[] {
    return ['/admin-pages', 'chats'];
  }

  navigateToAdminAllChats(): void {
    this.router.navigate(this.getAdminAllChatsUrl());
  }

  getExpertAllChatsUrl(): any[] {
    return ['/dashboard', 'all-chats', 'by-expert'];
  }

  navigateToExpertAllChats(): void {
    this.router.navigate(this.getExpertAllChatsUrl());
  }

  getClientAllChatsUrl(): any[] {
    return ['/dashboard', 'all-chats', 'by-client'];
  }

  navigateToClientAllChats(): void {
    this.router.navigate(this.getClientAllChatsUrl());
  }

  getChatUrl(id: number, user: User): any[] {
    if (user.isAdmin) {
      return this.getAdminChatUrl(id);
    }
    if (user.isExpert) {
      return this.getExpertChatUrl(id);
    }
    return this.getClientChatUrl(id);
  }

  getAdminEngagementsForPaymentUrl(): any[] {
    return ['/admin-pages', 'engagements-for-payment'];
  }

  navigateToAdminEngagementsForPayment(): void {
    this.router.navigate(this.getAdminEngagementsForPaymentUrl());
  }

  getAdminEngagementsForPaymentByOrganizationUrl(organizationId: number): any[] {
    return ['/admin-pages', 'engagements-for-payment', 'organization', organizationId];
  }

  navigateToAdminEngagementsForPaymentByOrganization(organizationId: number): void {
    this.router.navigate(this.getAdminEngagementsForPaymentByOrganizationUrl(organizationId));
  }

  getAdminAddNewOrg(): any[] {
    return ['/admin-pages', 'add-new-organization'];
  }

  navigateToAdminAddNewOrg(): void {
    this.router.navigate(this.getAdminAddNewOrg());
  }

  getAdminAllExpertsUrl(): any[] {
    return ['/dashboard', 'admin', 'all-experts'];
  }

  navigateToAdminAllExperts(): void {
    this.router.navigate(this.getAdminAllExpertsUrl());
  }

  getAdminExpertUrl(expertId: number): any[] {
    return ['/dashboard', 'admin' , 'expert', expertId];
  }

  navigateToAdminExpert(expertId: number): void {
    this.router.navigate(this.getAdminExpertUrl(expertId));
  }

  getAdminEngagementSuggestedTimesUrl(engagementId: number): any[] {
    return ['/admin-pages', 'engagement-suggested-times', engagementId];
  }

  navigateToAdminEngagementSuggestedTimes(engagementId: number): void {
    this.router.navigate(this.getAdminEngagementSuggestedTimesUrl(engagementId));
  }

  getAdminEmailHistoryUrl(): any[] {
    return ['/admin-pages', 'email-history'];
  }

  navigateToAdminEmailHistory(): void {
    this.router.navigate(this.getAdminEmailHistoryUrl());
  }

  getAdminUserEventsUrl(): any[] {
    return ['/admin-pages', 'user-events'];
  }

  navigateToAdminUserEvents(): void {
    this.router.navigate(this.getAdminUserEventsUrl());
  }

  getAdminOrganizationsListUrl(): any[] {
    return ['/admin-pages', 'organizations-list'];
  }

  navigateToAdminOrganizationsList(): void {
    this.router.navigate(this.getAdminOrganizationsListUrl());
  }

  getAdminMyOperationTasksUrl(): any[] {
    return ['/admin-pages', 'my-operation-tasks'];
  }

  navigateToAdminMyOperationTasks(): void {
    this.router.navigate(this.getAdminMyOperationTasksUrl());
  }

  getAdminManageOperationTasksUrl(): any[] {
    return ['/admin-pages', 'manage-operation-tasks'];
  }

  navigateToAdminManageOperationTasks(): void {
    this.router.navigate(this.getAdminManageOperationTasksUrl());
  }

  getAdminBusinessDataUrl(): any[] {
    return ['/admin-pages', 'business-data'];
  }

  navigateToAdminBusinessData(): void {
    this.router.navigate(this.getAdminBusinessDataUrl());
  }

  getAdminUserTimelinesUrl(): any[] {
    return ['/admin-pages', 'users-timelines'];
  }

  navigateToAdminUserTimelines(): void {
    this.router.navigate(this.getAdminUserTimelinesUrl());
  }

  getAdminAdHocEmailUrl(): any[] {
    return ['/admin-pages', 'ad-hoc-email'];
  }

  navigateToAdminAdHocEmail(): void {
    this.router.navigate(this.getAdminAdHocEmailUrl());
  }

  getAdminOrganizationUsersUrl(organizationId: number): any[] {
    return ['/admin-pages', 'organization', organizationId, 'users'];
  }

  navigateToAdminOrganizationUsers(organizationId: number): void {
    this.router.navigate(this.getAdminOrganizationUsersUrl(organizationId));
  }

  getAdminABTestsUrl(): any[] {
    return ['/admin-pages', 'a-b-tests'];
  }

  navigateToAdminABTests(): void {
    this.router.navigate(this.getAdminABTestsUrl());
  }

  getTaskTrackerUrl(): any[] {
    return ['/dashboard', 'admin', 'task-tracker'];
  }

  getCompleteCallUrl(): any[] {
    return ['/dashboard', 'admin', 'complete-calls-payment'];
  }
  navigateToTaskTrackerUrl(): void {
    this.router.navigate(this.getTaskTrackerUrl());
  }

  getTestUrl(): any[] {
    return ['/test-page'];
  }

  navigateToTaskTracker(subProjectId?: number): void {
    const queryParams = subProjectId ? {subProjectId} : {};
    this.router.navigate(this.getTaskTrackerUrl(), {queryParams});
  }

  getPublicAboutUsUrl(): any[] {
    return ['/public', 'about-us'];
  }

  navigateToPublicAboutUs(): void {
    this.router.navigate(this.getPublicAboutUsUrl());
  }

  getPublicFaqUrl(): any[] {
    return ['/public', 'faq'];
  }

  navigateToPublicFaq(): void {
    this.router.navigate(this.getPublicFaqUrl());
  }

  getPublicPrivacyPolicyUrl(): string {
    return 'https://www.xperiti.com/privacy';
  }

  navigateToPublicPrivacyPolicy(): void {
    this.router.navigate([this.getPublicPrivacyPolicyUrl()]);
  }

  getPublicTermsAndConditionsUrl(): any[] {
    return ['/public', 'terms-and-conditions'];
  }

  navigateToPublicTermsAndConditions(): void {
    this.router.navigate(this.getPublicTermsAndConditionsUrl());
  }

  getNextExpertOnboardingStepUrl(): any[] {
    return ['/expert-registration', 'onboarding'];
  }

  navigateToNextExpertOnboardingStep(step: ExpertOnboardingSteps, currentParameters?: Params): void {
    this.router.navigate([...this.getNextExpertOnboardingStepUrl(), step], {queryParams: currentParameters});
  }

  navigateToNextExpertOnboardingStart(currentParameters?: Params): void {
    this.router.navigate([...this.getNextExpertOnboardingStepUrl(), ExpertOnboardingSteps.WELCOME], {queryParams: currentParameters});
  }

  getAdminDemoTemplatesUrl(): any[] {
    return ['/admin-pages', 'demo-templates'];
  }

  getExpertInvitationUrl(expertId: number): any[] {
    return ['/expert-registration', 'expert-invitation', expertId];
  }

  navigateToExpertInvitation(expertId: number): void {
    this.router.navigate(this.getExpertInvitationUrl(expertId));
  }


  navigateToRedirectLink(externalLink: string): void {
    if (externalLink) {
      window.location.href = externalLink;
    }
  }

  getUrlWithoutBase(url:string): any[] {
    const parsedUrl = new URL(url);
    const pathAfterBase = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
    return pathAfterBase.split('/');
  }

  getDashboardClientSelectedSubProjectOverviewUrl(subProjectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected', subProjectId, 'overview'];
  }

  navigateToDashboardClientSelectedSubProjectOverview(subProjectId: number): void {
    this.router.navigate(this.getDashboardClientSelectedSubProjectOverviewUrl(subProjectId));
  }

  getDashboardClientSelectedSubProjectSegmentsUrl(subProjectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected', subProjectId, 'segments'];
  }
  navigateToDashboardClientSelectedSubProjectSegments(subProjectId: number, segmentTemplateToAttachId?:number): void {
    this.router.navigate(this.getDashboardClientSelectedSubProjectSegmentsUrl(subProjectId),{queryParams: {segmentTemplateToAttachId}});
  }

  getDashboardClientSelectedSubProjectParticipantsUrl(subProjectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected', subProjectId, 'participants'];
  }

  getDashboardClientSelectedSubProjectScreenerResponsesUrl(subProjectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected', subProjectId, 'screener-responses'];
  }

  getDashboardClientSelectedSubProjectInterviewsUrl(subProjectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected', subProjectId, 'interviews'];
  }

  getDashboardClientSelectedSubProjectAvailabilityUrl(subProjectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected', subProjectId, 'availability'];
  }

  getDashboardClientSelectedSubProjectIncentivesUrl(subProjectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected', subProjectId, 'incentives'];
  }

  getDashboardClientSelectedSubProjectScreenerQuestionsUrl(subProjectId: number): any[] {
    return ['/dashboard', 'client', 'study', 'selected', subProjectId, 'screener-questions'];
  }
}
